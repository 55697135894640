import {
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import './App.scss';
import Footer from './components/Footer';
import NavTop from './components/NavTop';
import AusstellerFormularPage from "./container/AusstellerFormularPage";
import DownloadPage from './container/DownloadPage';
import GeschichtePage from './container/GeschichtePage';
import HomePage from './container/HomePage';
import ImpressionenPage from './container/ImpressionenPage';
import LiedPage from './container/LiedPage';
import ProgrammPage from './container/ProgrammPage';
import ScrollToTop from './functions/ScrollToTop';
import { useApi } from "./services/useApi";

function App() {

  let { Data, Loading, Error } = useApi("/startseite?populate=*")
  if (Loading) return <p className="m-5 text-center">Die Website wird geladen...</p>
  if (Error) return <p>Wartungsarbeiten. Bitte versucehen Sie es später nochmal.</p>
  if (Data) {
    let year = new Date(Data.countdownDate).getFullYear() || "2023"
    Data.year = year
    return (
      <Router>
        <div className="App">
          <ScrollToTop />
          <NavTop data={Data} />
          <div className="pages">
            <Switch>
              <Route path="/" exact>
                <HomePage data={Data} />
              </Route>



              <Route path="/aussteller" exact>
                <DownloadPage data={Data} />
              </Route>


              <Route path="/aussteller/beta-geheimer-link-vorschau" exact>
                <AusstellerFormularPage data={Data} />
              </Route>


              <Route path="/programm" exact>
                <ProgrammPage data={Data} />
              </Route>
              <Route path="/geschichte" exact>
                <GeschichtePage data={Data} />
              </Route>
              <Route path="/impressionen" exact>
                <ImpressionenPage data={Data} />
              </Route>
              <Route path="/lieder" exact>
                <LiedPage data={Data} />
              </Route>
              <Route path="/">
                <p>Seite nicht gefunden.</p>
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    )
  }
}
export default App;