import React from 'react'
import { Helmet } from 'react-helmet'
import DefaultPage from '../components/pages/DefaultPage'
import { getDataFromSingleComponent, StrapiMain } from "../services/useApi"
import "./ProgrammPage.scss"

function ProgrammPage({ data }) {

    let year = new Date(data.countdownDate).getFullYear()
    return (
        <DefaultPage uri="programm" className='ProgrammPage'>
            <Helmet>
                <title>Programm – Gallusmarkt Grünberg</title>
                <meta name="description" content="Hier finden Sie das offizielle Programm zum Gallusmarkt 2022." />
            </Helmet>



            <div className="p-3 container">
                <h1>Programm {year}</h1>
                <p className='mb-5'>
                    <strong>{data.number}. Grünberger Gallusmarkt</strong>
                </p>

                {data.showProgramm ?
                    <Content data={data} />
                    :
                    <div className='mb-5 pb-5'>
                        <h2>Das Programm ist noch in Arbeit...</h2>
                        <p>Unsere Mitarbeiter:innen arbeiten noch an dem Programm für {year}. Bitte schauen Sie später nochmal vorbei!</p>
                    </div>
                }
            </div>
        </DefaultPage>
    )
}
export default ProgrammPage

function Content({ data }) {
    let programmPdf = getDataFromSingleComponent(data.programmPdf) || {}


    let downloads = [
        {
            title: programmPdf?.caption || "PDF-Programm",
            url: StrapiMain + programmPdf?.url,
            size: "In neuem Tab öffnen",
        },
    ]

    downloads.push({
        title: "Mobiles-Programm",
        url: "https://mittelhessen.app/grünberg/",
        size: "In der Grünberg-App",
    },)

    console.log(downloads)

    return (
        <div className="entry-content ">


            {/* Auflistung von den Tagen */}
            {data.days.map((i, k) =>
                <div className="mb-5">
                    <h2>{i.title}</h2>
                    <p>
                        {i.description}
                    </p>
                </div>
            )}

            <hr></hr>
            <h2>Programm für Unterwegs</h2>
            <div className="mt-5 row">
                {downloads.map((i, k) =>
                    <div className="col-12 col-lg-6 mb-3">
                        <a className="w-100" href={i.url} key={k} target="_blank">
                            <button className="w-100">
                                {i.title}
                                <br />
                                <small>{i.size}</small>
                            </button>
                        </a>
                    </div>
                )}
            </div>
        </div>

    )
}

const PDF_FOLDER = process.env.PUBLIC_URL + "/assets/pdf/"
